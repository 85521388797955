import { DistanceConfig } from "@/store/modules/reception";
import { Modal } from "@/ui";

export const isEmpty = function (str: unknown) {
  return str === undefined || str === null || str === "" || str === false;
};
export const getDoubleText = function (str: string) {
  return str.length == 1 ? 0 + str : str;
};

export const isArray = function (arr: unknown) {
  return Object.prototype.toString.call(arr) === "[object Array]";
};
export const modalTip = function (content: string) {
  return new Promise<void>((resolve) => {
    Modal({
      title: "提示",
      content,
      cancelBtn: false,
      onConfirm: () => {
        resolve();
      },
    });
  });
};

/**
 * 获取当前网页环境
 * @return env 1浙里办APP 2支付宝浙里办小程序 3浏览器 4微信小程序
 */
export const getWebEnv = function (): number {
  let res = 3;
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("dtdreamweb") !== -1) {
    res = 1;
  } else if (ua.indexOf("miniprogram") !== -1) {
    if (ua.indexOf("alipay") !== -1) {
      res = 2;
    } else {
      res = 4;
    }
  }
  return res;
};

/**
 * base64转文件
 * @param base64
 */
export const base64ToFile = function (base64: string, fileName: string): File {
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bStr = atob(arr[1]);
  let n = bStr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

/**
 * 获取文件大小展示文案
 * @param size
 */
export const getFileSizeText = function (size: number): string {
  const kb = 1024,
    mb = 1024 * 1024,
    gb = 1024 * 1024 * 1024;
  if (size < kb) {
    return `${size}B`;
  } else if (size < mb) {
    return `${Math.round(size / kb)}K`;
  } else if (size < gb) {
    return `${Math.round(size / mb)}M`;
  } else {
    return `${Math.round(size / gb)}G`;
  }
};

/**
 * 根据常量值获取常量名称
 * @param list 常量列表数组
 * @param value 常量值
 * @param valKey 常量值key
 * @param titleKey 常量名称key
 * **/
export const getValueTitle = function <T, P = T>(
  list: Array<Record<string, T | P>>,
  value: T,
  valKey = "value",
  titleKey = "text"
): P | undefined {
  let res = null;
  if (list) {
    res = list.find((item) => item[valKey] == value);
  }
  return res ? (res[titleKey] as P) : undefined;
};

export const getDate = function (date: string): Date | null {
  if (date) {
    const arr = date.split(/[-:\s]/).map(Number);
    const arg = arr.concat(new Array(6 - arr.length).fill(0));
    arg[1] = arg[1] && arg[1] > 0 ? arg[1] - 1 : 0;
    return new Date(arg[0], arg[1], arg[2], arg[3], arg[4], arg[5]);
  } else {
    return null;
  }
};

export const isUrl = function (str: string) {
  return /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/.test(
    str
  );
};

/**
 * 将页面上的图片绘制到canvas中
 * @param ctx
 * @param image
 */
export const drawDomImage = (
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement | null
) => {
  if (image) {
    const rect = image.getBoundingClientRect();
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      rect.left,
      rect.top,
      image.clientWidth,
      image.clientHeight
    );
  }
};

export const pxToVw = function (px: number | string) {
  return ((px as number) / 375) * 100 + "vw";
};

export const getDistance = function (config: DistanceConfig): string {
  if (!config) return "";
  let { top, right, bottom, left } = config;
  top = pxToVw(top) || 0;
  right = pxToVw(right) || 0;
  bottom = pxToVw(bottom) || 0;
  left = pxToVw(left) || 0;
  return `${top} ${right} ${bottom} ${left}`;
};

export const getTimePeriod = function (startTime: string, endTime: string) {
  let res = "";
  if (startTime && endTime) {
    const start = getDate(startTime);
    const end = getDate(endTime);
    if (start && end) {
      const sd = start.pattern("yyyy-MM-dd"),
        st = start.pattern("HH:mm");
      const ed = end.pattern("yyyy-MM-dd"),
        et = end.pattern("HH:mm");
      if (sd == ed) {
        res = `${sd} ${st} - ${et}`;
      } else {
        res = `${sd} ${st} ~ ${ed} ${et}`;
      }
    }
  }
  return res;
};

/**
 * 根据常量值获取常量名称
 * @param list 常量列表数组
 * @param value 常量值
 * @param valKey 常量值key
 * @param nameKey 常量名称key
 * **/
export const getKeyTitle = function <T extends { [key: string]: unknown }>(
  list: Array<T>,
  value: T[keyof T],
  valKey: keyof T = "key",
  nameKey: keyof T = "title"
): T[keyof T] | undefined {
  let res: T | undefined;
  if (list) {
    res = list.find((item) => item[valKey] == value);
  }
  return res ? res[nameKey] : undefined;
};

export const capitalizeWords = function (str: string): string {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const randomString = function (len?: number) {
  len = len || 32;
  const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

export const lowercaseFirstWord = function (words: string) {
  if (words.length === 0) {
    return words;
  }
  return words.charAt(0).toLowerCase() + words.slice(1);
};

/* eslint-disable */
export const deepClone: any = function (obj: any) {
  let o: any;
  switch (typeof obj) {
    case "undefined":
      break;
    case "string":
      o = obj + "";
      break;
    case "number":
      o = obj - 0;
      break;
    case "boolean":
      o = obj;
      break;
    case "object":
      if (obj === null) {
        o = null;
      } else {
        if (obj instanceof Array) {
          o = [];
          for (let i = 0, len = obj.length; i < len; i++) {
            o.push(deepClone(obj[i]));
          }
        } else if (obj instanceof Date) {
          o = new Date(obj);
        } else {
          o = {};
          for (const k in obj) {
            o[k] = deepClone(obj[k]);
          }
        }
      }
      break;
    default:
      o = obj;
      break;
  }
  return o;
};
