const dev = false;
const localHost = "https://wb.zj-qq.cn";
const proHost = "https:///wb.zj-qq.cn/weixin";
const devHost = dev ? localHost : proHost;
export const HOST = process.env.NODE_ENV === "production" ? proHost : devHost;
export const TOKEN_EXPIRE = 10 * 24 * 60 * 60 * 1000 - 60 * 1000; // token 有效期
export const DESENSITIZE_INFO = true; // 用户信息是否脱敏展示
export const APP_ID = "wx5b1eacd0434c25d3";
export const H5_PREFIX = "/h5";
export const WEIXIN_PREFIX = "/weixin";
