/**
 * UI组件库入口文件
 * @description 各个组件层级z-index划分（由高到底）
 * actionSheet 101
 * preview 100
 * modal 99
 * toast 98
 * picker 10
 */

import { App } from "vue";

// 界面组件
import Icon from "./icon";
import CModal from "./modal";
import CPop from "./pop";
import CCalendar from "./calendar";
import ActionSheet from "./action-sheet";

// 表单组件
import CCar from "./car";
import CInput from "./input";
import CPicker, { CPickerColumn } from "./picker";
import CSelect from "./select";
import CRadio from "./radio";
import CCheckbox from "./checkbox";
import CTip from "./tip";
import CUpload from "./upload";
import CLabel from "./label";
import CButton from "./button";
import CForm from "./form";

// 插件
import ToastPlugin from "./toast/plugin";
import ModalPlugin, { hideModal } from "./modal/plugin";
import ActionSheetPlugin, { hideActionSheet } from "./action-sheet/plugin";

export {
  ToastPlugin as Toast,
  ModalPlugin as Modal,
  ActionSheetPlugin as ActionSheet,
};

export * from "./modal";
export * from "./calendar";

/**
 * 隐藏所有Toast Modal Preview ActionSheet
 */
export const hideUI = function () {
  ToastPlugin.hideLoading();
  hideModal();
  hideActionSheet();
};

export default function install(app: App) {
  //注册组件
  app.component("CIcon", Icon);
  app.component("CModal", CModal);
  app.component("CPop", CPop);
  app.component("CCalendar", CCalendar);
  app.component("ActionSheet", ActionSheet);

  //表单组件
  app.component("CCar", CCar);
  app.component("CInput", CInput);
  app.component("CPickerColumn", CPickerColumn);
  app.component("CPicker", CPicker);
  app.component("CSelect", CSelect);
  app.component("CRadio", CRadio);
  app.component("CCheckbox", CCheckbox);
  app.component("CTip", CTip);
  app.component("CUpload", CUpload);
  app.component("CLabel", CLabel);
  app.component("CButton", CButton);
  app.component("CForm", CForm);

  // 引入插件
  app.use(ToastPlugin);
  app.use(ModalPlugin);
  app.use(ActionSheetPlugin);
}
