import { getUserId } from "@/common/storage";
import http from "../request";
import { H5_PREFIX } from "@/config";
import {
  CustomResponse,
  DeliveryCar,
  DeliveryServiceInfo,
  EntityListData,
} from "..";

export interface DeliveryService {
  id: number;
  h_user_id: number; // h5用户ID
  activity_id: number; // 活动id
  car_info: string; // 预约车类型和数量
  location: string; // 接站地点
  leave_location: string; // 送站地点
  train_flight: string; // 车次或者航班
  arrival_time: string; // 接站时间
  user_name: string; // 姓名
  mobile: string; // 联系方式
  email: string; // 邮箱
  status: number; // 派车状态 1未排除 2已派车
  email_status: number; // 邮件发送状态 1未发送 2已发送
  deliveryCar?: DeliveryCar[];
  deliveryInfo?: DeliveryServiceInfo[];
  create_time: string;
  update_time: string;
}
export interface CarInfo {
  name: string;
  name_en: string;
  price: number;
  dollar: number;
  number: number;
}

export type DeliveryServiceData = Pick<
  DeliveryService,
  | "h_user_id"
  | "car_info"
  | "location"
  | "train_flight"
  | "arrival_time"
  | "user_name"
  | "mobile"
  | "email"
>;

export interface CarType {
  activity_delivery_set_id: number;
  num: number;
}

export type DeliveryServiceCustomData = {
  h_user_id: number; // h5用户ID
  location: string; // 接站地点
  leave_location: string; // 送站地点
  train_flight: string; // 车次或者航班
  arrival_time: string; // 接站时间
  user_name: string; // 姓名
  mobile: string; // 联系方式
  email: string; // 邮箱
  car_type: CarType[];
};

const baseUrl = `${H5_PREFIX}/delivery-service`;

export interface DeliveryServiceApi {
  postDeliveryService: (data: DeliveryServiceData) => Promise<DeliveryService>;
  getDeliveryServiceById: () => Promise<DeliveryService | null>;
  getUserDeliveryService: () => Promise<DeliveryService[]>;
  getDeliveryServiceDetail: (id: number) => Promise<DeliveryService>;
  postDeliveryServiceCustom: (
    data: DeliveryServiceCustomData
  ) => Promise<CustomResponse>;
}

const deliveryServiceApi: DeliveryServiceApi = {
  postDeliveryService(data) {
    return http.post<DeliveryService>(baseUrl, data).then((res) => res.data);
  },
  getDeliveryServiceById() {
    const id = getUserId(H5_PREFIX);
    return http
      .get<EntityListData<DeliveryService>>(
        `${baseUrl}?filter[h_user_id]=${id}`
      )
      .then((res) => {
        const list = res.data.data;
        if (list.length > 0) {
          return list[0];
        } else {
          return null;
        }
      });
  },
  getUserDeliveryService() {
    const id = getUserId(H5_PREFIX);
    return http
      .get<EntityListData<DeliveryService>>(
        `${baseUrl}?pageSize=99&expand=deliveryInfo.activityDeliverySet&filter[h_user_id]=${id}`
      )
      .then((res) => res.data.data);
  },
  getDeliveryServiceDetail(id) {
    return http
      .get<DeliveryService>(
        `${baseUrl}/${id}?expand=deliveryCar.activityDeliverySet,deliveryInfo.activityDeliverySet`
      )
      .then((res) => res.data);
  },
  postDeliveryServiceCustom(data) {
    return http
      .post<CustomResponse>(`${baseUrl}/order`, data)
      .then((res) => res.data);
  },
};

export default deliveryServiceApi;
