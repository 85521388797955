import { H5_PREFIX, TOKEN_EXPIRE, WEIXIN_PREFIX } from "../config";
import { CustomResponse } from "@/api/type";
import store from "@/store";
import { capitalizeWords } from "./tool";

export const TOKEN_KEY = "token";
export const UID_KEY = "uid";
export const INFO_KEY = "info";

export const H_TOKEN_KEY = `h${capitalizeWords(TOKEN_KEY)}`;
export const H_UID_KEY = `h${capitalizeWords(UID_KEY)}`;
export const H_INFO_KEY = `h${capitalizeWords(INFO_KEY)}`;

interface StorageKeyMap {
  [prefix: string]: {
    setToken: string;
    setUid: string;
    setUser: string;
    token: string;
    uid: string;
    info: string;
  };
}

const storageKeyMap: StorageKeyMap = {
  [WEIXIN_PREFIX]: {
    setToken: "setToken",
    setUid: "setUid",
    setUser: "setUser",
    token: TOKEN_KEY,
    uid: UID_KEY,
    info: INFO_KEY,
  },
  [H5_PREFIX]: {
    setToken: "setHToken",
    setUid: "setHUid",
    setUser: "setHUser",
    token: H_TOKEN_KEY,
    uid: H_UID_KEY,
    info: H_INFO_KEY,
  },
};

export type AuthToken = {
  timestamp: number;
  value: string;
} | null;
let token: AuthToken = null;

export const getUserId = function (prefix: string = WEIXIN_PREFIX) {
  const keys = storageKeyMap[prefix];
  if (!keys) return 0;
  let id = store.getters[keys.uid];
  if (!id) {
    const sid = localStorage.getItem(keys.uid);
    if (sid) {
      id = Number(sid);
      store.commit(keys.setUid, id);
    }
  }
  return id || 0;
};

export const getUser = function (prefix: string = WEIXIN_PREFIX) {
  const keys = storageKeyMap[prefix];
  if (!keys) return;
  let user = store.getters[keys.info];
  if (!user) {
    const u = getStorage(keys.info);
    if (u) {
      user = u;
      store.commit(keys.setUser, user);
    }
  }
  return user;
};

export const getToken: (prefix?: string) => AuthToken = function (
  prefix: string = WEIXIN_PREFIX
) {
  if (!token) {
    console.log("Read token from storage.");
    token = getStorage(storageKeyMap[prefix].token);
  }
  //判断token是否过期 过期返回null
  if (token && Date.now() - token.timestamp > TOKEN_EXPIRE) {
    token = null;
  }
  // token = {
  //   timestamp: Date.now(),
  //   value: "0077247bfb78a730e5c78663ff7ee603",
  // };
  // localStorage.setItem(UID_KEY, "5");
  return token;
};

export const setToken = function (
  data: CustomResponse,
  prefix: string = WEIXIN_PREFIX
) {
  const keys = storageKeyMap[prefix];
  if (!keys) return;
  if (data.token) {
    const temp = {
      timestamp: Date.now(),
      value: data.token,
    };
    const user = data.WxUser || data.user;
    const value = JSON.stringify(temp);
    token = temp;
    store.commit(keys.setToken, token);
    localStorage.setItem(keys.token, value);
    if (user) {
      store.commit(keys.setUid, user.id);
      store.commit(keys.setUser, user);
      localStorage.setItem(keys.uid, user.id + "");
      localStorage.setItem(keys.info, JSON.stringify(user));
    }
  }
};

export const getStorage: (key: string) => AuthToken = function (key: string) {
  let result = null;
  const storage = String(localStorage.getItem(key));
  try {
    result = JSON.parse(storage);
  } catch (e) {
    console.log(`Storage for ${key} parse failed.`);
  }
  return result;
};

export const clearStorage = function (prefix: string = WEIXIN_PREFIX): void {
  const keys = storageKeyMap[prefix];
  token = null;
  localStorage.removeItem(keys.token);
};
