import { App, Plugin } from "vue";
import axios, { AxiosInstance } from "axios";
import { H5_PREFIX, HOST, WEIXIN_PREFIX } from "../config";
import auth, { showIpccAuth } from "./auth";
import { getToken } from "@/common/storage";
import { Toast } from "@/ui";

export const H5_IPCC_ACTIVITY_ID = 64;

type HttpPlugin = Plugin & AxiosInstance;
const HttpPlugin: HttpPlugin = axios.create({
  baseURL: HOST,
  responseType: "json",
}) as HttpPlugin;

let controller: AbortController;

HttpPlugin.interceptors.request.use((config) => {
  let uri = config.url + "";
  if (
    !/^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#])?$/.test(
      uri
    )
  ) {
    // IPCC接口 自带/h5开头
    let token;
    let host = HOST;
    if (uri.includes(H5_PREFIX)) {
      token = getToken(H5_PREFIX);
      host = host.split(WEIXIN_PREFIX)[0];
      const method = config.method?.toUpperCase() || "GET";
      if (method === "GET") {
        let prefix = "?";
        if (uri && uri.includes("?")) {
          prefix = "&";
        }
        uri += `${prefix}filter[activity_id]=${H5_IPCC_ACTIVITY_ID}`;
      } else if (method === "POST") {
        if (config.data) {
          config.data = {
            ...config.data,
            activity_id: H5_IPCC_ACTIVITY_ID,
          };
        }
      }
    }
    config.url = host + uri;
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token.value}`;
      // 文件上传请求不指定content-type(指定了 在app中formData请求不能自动设置content-type)
      if (!config.upload && !config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json";
      }
    }
    controller = new AbortController();
    config.signal = controller.signal;
  }
  return config;
});

HttpPlugin.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log("err:", err.response);
    const response = err.response;
    const status = response ? response.status : undefined;
    if (status == 401) {
      if (location.pathname.includes("/ipcc")) {
        showIpccAuth();
      } else {
        auth();
      }
    } else if (err.response && err.response.data && err.response.data.message) {
      Toast(err.response.data.message);
    }
    return Promise.reject(err);
  }
);

HttpPlugin.install = (app: App) => {
  app.config.globalProperties.$http = HttpPlugin;
};

export default HttpPlugin;
export const abortRequest = function () {
  controller?.abort();
};
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $http: HttpPlugin;
  }
}
declare module "axios" {
  interface AxiosRequestConfig {
    /**
     * 不显示错误信息
     */
    noError?: boolean;
    /**
     * 是否是文件上传请求
     * */
    upload?: boolean;
  }
}
