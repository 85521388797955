import { Lang } from "@/store/modules/reception";

export interface LangText {
  [key: string]: string;
}
export type LangTextMap = {
  [key in Lang]: LangText;
};

const testMap: LangTextMap = {
  zh: {
    colon: "：",
    prompts: "提示",
    confirm: "确定",
    auth: "授权",
    cancel: "取消",
    authTip: "您尚未登录，请先登录授权！",
    home: "首页",
    user: "微信用户",
    unAuth: "未登录，点击授权登录",
    center: "个人中心",
    info: "个人信息",
    journey: "行程安排",
    meal: "用餐安排",
    stay: "住宿安排",
    searching: "查询中...",
    mealInfo: "用餐信息",
    mealTime: "用餐时间",
    hotelName: "酒店名称",
    hotelAddress: "酒店地址",
    mealPlace: "用餐地点",
    mealEmpty: "暂无用餐安排",
    stayInfo: "住宿信息",
    roomType: "房型",
    stayEmpty: "暂无住宿信息",
    journeyInfo: "行程安排",
    arrivalInfo: "到达信息",
    arrivalType: "到达方式",
    arrivalNumber: "航班车次",
    carNumber: "车牌号",
    arrivalTime: "到达时间",
    pickAddress: "接站地址",
    departuresInfo: "离开信息",
    departuresType: "离开方式",
    departuresTime: "离开时间",
    vehicleInfo: "车辆安排",
    dispatchTime: "派车时间",
    driverName: "司机姓名",
    contactNumber: "联系电话",
    receptionist: "接待人员",
    name: "姓名",
    mobile: "手机号",
    sex: "性别",
    unset: "未设置",
    save: "保存",
    edit: "编辑",
    submit: "提交",
    submitTip: "确定提交吗？",
    submitSuccess: "提交成功",
    idCardError: "身份证号码格式不正确",
    riefingTitle: "国际会议外事通",
    wstLink: "点击查看官网",
    signIn: "签到",
    agenda: "会议议程",
    cloudPhoto: "云相册",
    namePlaceholder: "请输入姓名",
    mobilePlaceholder: "请输入手机号",
    sexPlaceholder: "请选择性别",
    registerTip: "未查询到该人员，请联系工作人员",
    hasSign: "您已签到",
    signSuccess: "签到成功",
    signFail: "签到失败",
    guestQueryFail: "嘉宾查询失败",
    signTip: "请凭此界面在签到处获取入场手环",
    signTipWithTicket: "请凭此界面在签到处获取入场手环与新年音乐会门票",
    screenshotTip: "（需要此截图界面，请不要退出）",
    male: "男",
    female: "女",
    hasRegister: "您已注册",
    hasRegisterSign: "您已注册签到",
    registerSuccess: "注册签到成功",
    registerFail: "注册失败",
    registerSign: "注册签到",
    introduce: "杭州市情介绍",
    weather: "智慧天气",
    guide: "交通指引",
    meeting: "会议安排",
    layout: "会场布局",
    service: "功能区\n介绍",
    seat: "座位查询",
    food: "订餐服务",
    culture: "文旅服务",
    scenery: "杭州著名\n景点介绍",
    product: "产品介绍",
    currency: "货币",
    voltage: "电压",
    tel: "紧急电话",

    register: "注册",
    login: "登录",
    userCenter: "用户",
    loginTip: "请先登录",
    email: "邮箱",
    password: "密码",
    inputPlaceHolder: "请输入",
    registerTitle: "填写信息",
    contactInformation: "联系方式",
    confirmPassword: "确认密码",
    emailEffective: "请输入有效的邮箱地址",
    passwordPlaceholder: "请输入密码",

    historyEvolution: "历史沿革",
    physicalGeography: "自然地理",
    administrativeArea: "行政区域",
    historyCulture: "历史文化",
    famousProduct: "名优特产",
    job: "人口就业",

    locationLabel: "位置：",
    locationsLabel: "位置：",
    contentLabel: "服务内容：",
    timeLabel: "服务时间：",

    serviceTitle: "功能区位置介绍",
    networkTitle: "网络通信办理处",
    materialTitle: "注册及资料领取处",
    healthTitle: "医疗服务点",
    infoTitle: "洲际酒店咨询台",
    teaTitle: "茶歇区",
    mealTitle: "洲际酒店用餐区",

    inauguration: "开幕式会场",
    network: "网络通信办理处",
    material: "注册及资料领取处",
    health: "医疗服务点",
    information: "咨询台",
    tea: "茶歇区",
    mealArea: "洲际酒店用餐区",

    networkLocation: "洲际酒店1号门内，注册处左侧",
    networkContent: "电话卡办理（嘉宾自费），网络卡发放（免费）",
    networkTime1: "2025年2月23日 14:00-18:00",
    networkTime2: "2025年2月24日-3月1日 08:00-17:00",

    materialLocation: "洲际酒店1号门内",
    materialContent: "会议签到注册、会议材料领取",
    materialTime1: "2025年2月23日 14:00-22:00",
    materialTime2: "2025年2月24日-3月1日 08:00-20:00",
    materialRegisterTime: "注册时间：2月23日14:00开始",

    healthLocation: "洲际酒店一楼贵宾休息室3",
    healthContent:
      "患者突发疾病、意外伤害等紧急医疗急救等（不包含病情稳定常见病、多发病的诊疗活动）",
    healthTime: "2025年2月24日-3月1日 09:00-19:00",

    infoLocation: "洲际酒店杭州厅裙廊",
    infoContent:
      "外事通咨询服务、嘉宾用餐、出行咨询引导服务、大会相关信息解答、转换插头售卖",
    infoTime1: "2025年2月23日 14:00-18:00",
    infoTime2: "2025年2月24日-3月1日 08:00-会议结束",

    teaLocation: "洲际酒店一楼杭州厅裙廊、一楼花园连廊",
    teaContent: "提供会期的茶歇,包括饼干、小面包、咖啡、茶等",
    teaTime:
      "2月24日上午9：00至3月1日晚上，每天上午、下午、晚上都会有茶歇提供 (24日晚上不提供)",

    mealLocation:
      "4楼日月轩中餐厅、5楼原素全日餐厅、5楼康桥大堂吧、负1楼面工坊、负1楼德国啤酒屋",
    mealContent:
      "4楼日月轩中餐厅提供商务套餐和零点用餐服务、5楼原素全日餐厅提供早晚自助及零点用餐服务、5楼康桥大堂吧提供下午茶及鸡尾酒、负1楼面工坊提供亚洲面食及意大利面、负1楼德国啤酒屋提供零点和酒水饮料",
    mealTime1: "4楼日月轩中餐厅营业时间：中午11：30-14:00，晚上17:30-22：30；",
    mealTime2: "5楼原素全日餐厅营业时间：晚上17:30-21:30，零点6:30-22:00；",
    mealTime3: "5楼康桥大堂吧营业时间：9:00-24:00",
    mealTime4: "B1面工坊营业时间：中午11:30-14:00，晚上17：30-21:00；",
    mealTime5: "B1德国啤酒屋营业时间：晚上17:30-23:00",

    meetingTitle: "会议议程",

    layoutTitle: "会场布局",
    openingCeremonyLabel: "开幕式：",
    breakoutRoomLabel1: "分组讨论室1：",
    breakoutRoomLabel2: "分组讨论室2：",
    privateMeetingRoom: "小型会议室：",
    openingCeremony: "杭州厅1、2、3、4（主会场）",
    breakoutRoom1: "杭州厅5、6、7",
    breakoutRoom2: "上海厅",
    privateMeeting: "重庆厅",

    guideTitle: "交通指引",
    guideTitleText1: "主会场、机场、高铁站",
    guideTitleText2: "配套酒店",
    guideMethodText: "通用交通方式",
    HangzhouInternationalHotel: "杭州国际会议中心洲际酒店",
    HangzhouXiaoshanAirport: "杭州萧山机场",
    HangzhouEastRailwayStation: "杭州火车东站",
    supportingReceptionHotel: "配套接待酒店",

    guideMethodWay1: "建议使用手机自带地图进行导航。",
    guideMethodWay2:
      "中国大陆地区常用导航为高德地图：可导航至目的地、搜索周边美食、查看景点路线等。",
    guideMethodWay3:
      "在市民中心地铁站可跟随图中指示前往杭州国际会议中心洲际酒店",

    guideWayFirstTitle1: "推荐方案一 ：地铁出行",
    guideWayFirstText1: "运营时间：05:50–00:27",
    guideWayFirstTitle2: "推荐方案二 ：出租车出行",
    guideWayFirstText2: "T3上客区:位于T3航站楼到达层14号门外",
    guideWayFirstText3: "T4上客区:位于T4航站楼与交通中心F1层连廊处",

    guideWaySecondTitle1: "推荐方案一 ：地铁出行",
    guideWaySecondText1: "运营时间：06:02–23:11",
    guideWaySecondTitle2: "推荐方案二 ：出租车出行",
    guideWaySecondText2: "杭州东站分别设置了出租车等候区(南)和出租车等候区(北)",

    cultureDeliveryStation: "送机站服务",
    cultureMobile: "紧急救助电话",
    culturePrice: "文旅服务价目表",
    cultureScenery: "杭州特色景区和特产",

    cultureMobileTitle1: "号码",
    cultureMobileTitle2: "类型",
    cultureMobileTitle3: "紧急情况",
    cultureMobileType1: "匪警电话",
    cultureMobileType2: "火警电话",
    cultureMobileType3: "急救电话",
    cultureMobileType4: "突发疾病、严重受伤等",
    cultureMobileEmergency1: "盗窃、抢劫、打架等",
    cultureMobileEmergency2: "火灾、人员受困等",
    cultureMobileEmergency3: "突发疾病、严重受伤等",
    cultureMobileEmergency4: "交通事故",

    cultureSceneryIntroTitle: "杭州景区介绍",
    cultureSceneryIntroContent:
      "杭州，是水做的。当杭州的江南水韵牵手世界遗产，碰撞出的是联通古今、薪传文明的国家历史文化名城基因。西湖、京杭大运河、良渚古城遗址，共同展示了中华文明，传承了浙江文脉，彰显着独特韵味、别样精彩的新天堂魅力。",
    cultureSceneryTitle1: "西湖",
    cultureSceneryTitle2: "运河",
    cultureSceneryTitle3: "良渚",
    cultureSceneryTitle4: "西湖龙井",
    cultureSceneryTitle5: "杭州丝绸",
    cultureSceneryTitle6: "糕点",
    cultureSceneryOneText1:
      "天下被称之为“西湖”的不下数十个，只此西湖脱颖而出。白居易是开卷勾勒之人，林和靖洒下一笔带着梅香的写意泼墨，苏轼更是以“淡妆浓抹”的比喻立下了一个西子湖的人设。还有许许多多的后来者，他们或吟诗作画，或结庐常驻，将西湖演绎成中国最具代表性的诗意栖居精神家园，充满着东方品质和美学韵味的人间天堂。",
    cultureSceneryOneText2:
      "西湖从来不是简单的山水湖。千百年来，她展示着”天人合一“的美学思想，辗转成为中国历代文人的”精神家园”和东方美学典范。在这里，你可以遇见史诗般的建筑美学，隽永的宋韵美学，多元的人文精神美学，包容共存的宗教美学，直击心灵的诗歌美学等，西湖的美学期待您的见证和创造。",
    cultureSceneryTwoText1:
      "钱塘自古繁华，这份“繁华”来自运河。从春秋时期走来的千年黄金水道，凝结了中华先人适应自然、改造自然与自然和谐共处的中国智慧，是活着的、流动着的中华文化遗产。翻到杭州这一章，它孕育和滋养了吴越文化、江南文化，留下两岸白墙黛瓦、枕水人家。时至今日，这千年的烟波之上，传统与时尚并存，坚守与创新并举，历史与新生共融。",
    cultureSceneryThirdText:
      "小洲为渚，良渚，即“美丽之洲”。正是从这片小洲里，流淌出了中华五千年文明之源。伫立在这片土地上，我们可以触摸华夏的原点，感知中华文脉的赓续，见证新生代的艺术创新。",
    specialProductTitle: "杭州特产介绍",
    cultureSceneryForthText1:
      "西湖龙井茶，产于中国浙江省杭州市西湖区，是中国十大名茶之一，以其鲜爽、甘醇的口感和独特的香气闻名。西湖龙井的茶树生长在西湖的山间，那里的气候湿润，土壤肥沃，为茶叶的生长提供了得天独厚的条件。其采摘严格，通常选用一芽一叶或一芽二叶的嫩叶，经过摊晾、杀青、揉捻、干燥等工艺制作而成。",
    cultureSceneryForthText2:
      "西湖龙井茶汤清澈明亮，香气高扬，带有栗香或豆香，口感鲜爽回甘，深受茶人喜爱。它不仅是日常饮品，还因其独特的品质和历史文化背景，成为了馈赠亲友和商务宴请的佳品。作为中国传统的文化象征，西湖龙井茶展现了中国茶文化的博大精深。",
    cultureSceneryFifthText1:
      "杭州丝绸，作为中国四大名绸之一，历史悠久，源远流长。杭州自古以来便是丝绸之路的重要起点，杭州丝绸的制作技艺代代相传，享誉国内外。它以其优质的原料和精湛的工艺而著称，主要以蚕丝为原料，经过缫丝、织造、染色、印花等多个复杂工艺精心制作而成。",
    cultureSceneryFifthText2:
      "杭州丝绸的特点是细腻光滑、色泽柔和，手感丝滑，具有良好的透气性和舒适性。传统的杭州丝绸产品包括丝绸服饰、床上用品以及各类工艺品。近年来，杭州丝绸还不断创新，设计现代化、工艺精细化，成为了国际时尚界的宠儿。",
    cultureSceneryFifthText3:
      " 作为杭州的文化名片，杭州丝绸不仅是中国传统工艺的代表，更承载了深厚的历史文化，是人们馈赠亲友、商务往来的佳品，展现了丝绸之美与中国传统文化的精致。",
    cultureScenerySixthText1:
      "杭州是中国传统糕点文化的发源地之一，其中许多经典糕点深受当地人和游客的喜爱，展现了这座城市的独特风味与历史底蕴。条头糕是杭州传统糕点的代表之一，采用糯米粉和红豆沙为主要原料，蒸制而成，外皮软糯，口感细腻，表面覆盖一层透明的糖浆，甜而不腻。它不仅味道可口，还象征着杭州人勤劳和细致的工艺精神。",
    cultureScenerySixthText2:
      "另一款经典糕点是定胜糕，其历史可以追溯到宋代，传说它能“定胜”心志，故得此名。定胜糕选用糯米粉、红枣、桂圆等食材，蒸制后口感紧实、富有弹性，甜香浓郁。它常常作为节庆和宴席的传统食品，寓意着好运和健康。",
    cultureScenerySixthText3:
      "此外，龙井茶饼则以杭州特产的西湖龙井茶为灵感，选用优质龙井茶叶与糯米粉结合，制作成饼。它清香扑鼻，口感软糯，带有茶香和淡淡的甜味，是品味杭州茶文化和糕点艺术的绝佳选择。",
    cultureScenerySixthText4:
      "这三款糕点无论是作为日常零食，还是赠送亲友，都是杭州传统美食的代表，体现了这座城市的丰富文化和精致工艺。",
    locationQuery: "位置查询",
    seatMaps: "座位图",

    chooseCountry: "请选择国家",

    currencySymbol: "¥",
    orderContacts: "订餐人",
    stand1: "杭州火车东站",
    stand2: "杭州萧山机场",
    stand3: "杭州国际会议中心洲际酒店",

    hotel1: "杭州钱江新城假日酒店",
    hotel2: "杭州康莱德酒店",
    hotel3: "杭州钱江新城万豪酒店",
    hotel4: "杭州钱江新城美居酒店",
    hotel5: "杭州国际会议中心洲际酒店",
    hotel6: "杭州钱江新城万怡酒店",
    hotel7: "杭州中科久泰饭店",
    hotel8: "全季酒店（杭州钱江新城店）",

    selectDate: "选择日期",
    selectTime: "选择时间",
    selectPlaceholder: "请选择",
    next: "下一步",
    totalNumText: "订餐数量合计：",
    number: "数量",
    reserve: "预定",
    emptyOrderText: "暂无订餐信息",
    orderDate: "订餐日期",
    orderTime: "订餐时间",

    backHome: "返回首页",
    submissionSuccessful: "提交成功",
    continueOrdering: "继续订餐",
    orderSuccessTip:
      "请至国际会议中心洲际酒店4楼中餐厅前台，\n出示预订界面，付费用餐。\n预订详情请至“我的”查看。",
    orderNotAuthTip:
      "如需使用该功能，需要注册、登陆之后，选择预订日期、时间和想要的商务套餐，提交成功之后，可至“我的”查看预订详情。用餐时间请至国际会议中心洲际酒店4楼中餐厅前台，出示预订界面，付费用餐。",
    orderAuthTip:
      "请选择预订日期、时间和想要的商务套餐，提交成功之后，可至“我的”查看预订详情。用餐时间请至国际会议中心洲际酒店4楼中餐厅前台，出示预订界面，付费用餐。",
    emptyOrderProductText: "当前时段暂无可定餐次",
    mealProducts: "餐品",

    orderNotStart: "订餐服务未开始",
    orderEnd: "订餐服务已结束",

    deliveryNotStart: "送机站服务未开始",
    deliveryEnd: "送机站服务已结束",
    submitted: "已提交",
    checkDetails: "查看详情",
    deliveryAuthTip:
      "请选择想要的车型、接站时间地点、航班/车次，并且输入客人的姓名和联系方式，提交成功后将进行车辆安排。最终车辆和司机信息将通过邮箱通知您，您也可以登录本网站-我的查看安排详情。",
    deliveryNotAuthTip:
      "如需使用该功能，需要注册、登陆之后， 选择想要的车型、接站时间地点、航班/车次，并且输入客人的姓名和联系方式，提交成功后将进行车辆安排。最终车辆和司机信息将通过邮箱通知您，您也可以登录本网站-我的查看安排详情。",
    deliverySuccessText1: "我们将尽快联系您，请注意接听电话；",
    deliverySuccessText2: "安排完成后结果将通过邮箱通知您，",
    deliverySuccessText3: "您也可以登录本网站-用户中心查看安排详情",
    precautions:
      "预定成功请前往杭州国际会议中心洲际酒店咨询台付款。本次服务支持支付方式：现金、支付宝、刷卡。如有疑问，可咨询现场工作人员，\n联系人：徐云+8613071821708",
    precautionsTip: "请勾选注意事项",

    selectCarModel: "选择车型",
    pickUpLocation: "接站地点",
    deliveryLocation: "送站地点",
    flightOrTrainNumber: "航班/车次",
    pickUpDate: "接站日期",
    pickUpTime: "接站时间",

    selectCarModelPlaceholder: "请选择车型",
    pickUpLocationPlaceholder: "请输入接站地点",
    deliveryLocationPlaceholder: "请输入送站地点",
    flightOrTrainNumberPlaceholder: "请输入航班/车次",
    pickUpDatePlaceholder: "请选择接站日期",
    pickUpTimePlaceholder: "请选择接站时间",
    emailPlaceholder: "请输入邮箱",
    contactInformationPlaceholder: "请输入联系方式",
    myOrder: "我的订餐",
    myService: "我的送机服务",
    submissionTime: "提交时间",
    serving: "份",
    phoneNumber: "联系电话",
    carModel: "车型",
    pickTime: "接站时间",
    noCarAssigned: "未派车",
    carAssigned: "已派车",
    vehicleInformation: "车辆信息",
    submitDetails: "提交详情",
    pickLocation: "接站地点",
    flightTrainNumber: "航班/车次",
    noInformationAvailable: "暂无信息",
    driverInfo: "司机姓名",
    companion: "陪同人员",

    technologyBy: "技术支持",
    confirmPasswordPlaceholder: "请再次输入密码确认",
    passwordRegExpPlaceholder:
      "请输入6-20个字符，需至少包含数字、字母、特殊字符中的任意两种",
    countryPlaceholder: "请选择国家",
    searchPlaceholder: "请输入搜索关键词",
    passwordNotMatch: "密码不一致",
    registerSuccessTip: "注册成功",
    left: "左",
    middle: "中",
    right: "右",
    row: "排",
    column: "号",
    numEmptyText: "暂无座位信息",
    productPlaceholder: "请选择餐品",
    seatImgTip: "点击图片横屏查看",
    seatTip: "单指移动，双指缩放，双击复原",
    logout: "退出",
    know: "我知道了",
    stage: "舞台",
  },
  en: {
    colon: ": ",
    prompts: "Prompts",
    confirm: "Confirm",
    auth: "Authorize",
    cancel: "Cancel",
    authTip: "Please sign in first!",
    home: "HOME",
    center: "PERSONAL CENTER",
    user: "WeChat User",
    unAuth: "Sign In",
    info: "Personal Information",
    journey: "Itinerary",
    meal: "Catering",
    stay: "Accommodations",
    searching: "Querying...",
    mealInfo: "Catering Facts",
    mealTime: "Dining Time",
    hotelName: "Hotel",
    hotelAddress: "Hotel Address",
    mealPlace: "Dining Place",
    mealEmpty: "No Data",
    stayInfo: "Accommodations Facts",
    roomType: "Room Type",
    stayEmpty: "No Data",
    journeyInfo: "Schedule",
    arrivalInfo: "Arrivals",
    arrivalType: "Means of Transport",
    arrivalNumber: "Flight/Train Number",
    carNumber: "Car Plate Number",
    arrivalTime: "Time of Arrival",
    pickAddress: "Place of Pick Up",
    departuresInfo: "Departures",
    departuresType: "Means of Transport",
    departuresTime: "Time of Departure",
    vehicleInfo: "Transport",
    dispatchTime: "Dispatch Time",
    driverName: "Driver",
    contactNumber: "Contact Number",
    receptionist: "Guide",
    name: "Name",
    mobile: "Mobile Phone Number",
    sex: "Sex",
    unset: "Not Set",
    save: "Save",
    edit: "Edit",
    submit: "Submit",
    submitTip: "Are you sure to submit?",
    submitSuccess: "Data Submitted Successfully",
    idCardError: "ID Card Number Error",
    riefingTitle: "GLOBAL MICE",
    wstLink: "Click to view the official website",
    signIn: "Sign in",
    agenda: "Agenda",
    cloudPhoto: "Photo Stream",
    namePlaceholder: "Please enter your name",
    mobilePlaceholder: "Please enter your phone number",
    sexPlaceholder: "Please select your gender",
    registerTip:
      "Sign-in information not found. Please contact staff for registration",
    signSuccess: "Sign-in completed",
    signFail: "Sign-in failed",
    guestQueryFail: "Query guest failed",
    hasSign: "Sign-in completed",
    signTip: "Please collect your wristband at the registration desk",
    signTipWithTicket:
      "Please collect your wristband and ticket for the BRLC New Year’s Concert at the registration desk",
    screenshotTip: "(sign-in screenshot required, please don’t quit)",
    male: "Male",
    female: "Female",
    hasRegister: "You’ve been registered",
    hasRegisterSign: "You’ve been registered (signed in)",
    registerSuccess: "Registration (sign-in) completed",
    registerFail: "Registration failed",
    registerSign: "Register (Sign in)",
    introduce: "Introduction to\nHangzhou",
    weather: "Smart\nWeather",
    guide: "Transportation\nGuide",
    meeting: "Conference\nSchedule",
    layout: "Venue\nLayout",
    service: "Functional\nArea\nIntroduction",
    seat: "Seat Inquiry",
    food: "Meal Ordering\nService",
    culture: "Cultural and\nTourism Services",

    register: "Register",
    login: "Log In",
    userCenter: "Account",
    loginTip: "Please log in first",
    email: "Email",
    password: "Password",
    inputPlaceHolder: "Please Enter",
    registerTitle: "Fill in Information",
    contactInformation: "Contact Information",
    confirmPassword: "Confirm\nPassword",
    emailEffective: "Please enter a valid email address",
    passwordPlaceholder: "Please enter a password",

    historyEvolution: "History",
    physicalGeography: "Geography",
    administrativeArea: "Administrative\nDivisions",
    historyCulture: "History and\nCulture",
    famousProduct: "Brand Local\nSpecialties",
    job: "Population and\nEmployment",

    locationLabel: "Location:",
    locationsLabel: "Locations:",
    contentLabel: "Services Offered:",
    timeLabel: "Service Hours:",

    serviceTitle: "Locations of\nFunctional Areas",
    networkTitle: "SIM Card and\nInternet Service",
    materialTitle: "Conference Registration\nand Material  Collection Desk",
    healthTitle: "Medical Service",
    infoTitle: "Information Desk at\nInterContinental Hangzhou",
    teaTitle: "Tea Break Areas",
    mealTitle: "Dining Areas at\nInterContinental Hangzhou ICC",

    inauguration: "Opening Ceremony\nVenue",
    network: "SlM card and\nInternet service",
    material: "Registration and\nMaterialCollection Desk",
    health: "Medical Service",
    information: "Information Desk",
    tea: "Tea Break Areas",
    mealArea: "Dining Areas at\nInterContinental Hangzhou ICC",

    networkLocation:
      "Inside InterContinental Hangzhou ICC, to the left of the registration desk, near Gate 1",
    networkContent:
      "SIM cards for phone services (at guest's own expense) and internet service (free of charge)",
    networkTime1: "February 23, 2025, 14:00-18:00",
    networkTime2: "February 24, 2025 - March 1, 2025, 08:00-17:00",

    materialLocation: "Inside InterContinental Hangzhou ICC, near Gate 1",
    materialContent:
      "Conference check-in and registration, and conference material collection",
    materialTime1: "February 23, 2025, 14:00-22:00",
    materialTime2: "February 24 - March 1,2025, 08:00-20:00",
    materialRegisterTime: "Starting from 14:00, Feb 23",

    healthLocation: "VIP Lounge 3, 1st Floor, InterContinental Hangzhou ICC",
    healthContent:
      "Emergency medical care for sudden illnesses or accidental injuries (excluding the diagnosis and treatment of stable, common, or frequently occurring diseases)",
    healthTime: "February 24 - March 1,2025, 09:00-19:00",

    infoLocation:
      "Skirt Corridor of Hangzhou Hall of InterContinental Hangzhou ICC",
    infoContent:
      "Foreign affairs consultation services, guest dining, travel consultation and guidance services, conference information inquiry services, and sale of adapter plugs",
    infoTime1: "February 23, 2025, 14:00-18:00",
    infoTime2:
      "February 24 - March 1, 2025, 08:00 - until the end of the conference",

    teaLocation:
      "Hangzhou Foyer (F1) and Garden Foyer B (F1) at InterContinental Hangzhou ICC",
    teaContent:
      "Services Offered: Tea break refreshments during IPCC-62, including cookies, small breads, coffee, tea, and other light snacks",
    teaTime:
      "From 9:00 on February 24 to the evening of March 1, tea breaks will be provided in the morning, afternoon, and evening each day (except for the evening of February 24)",

    mealLocation:
      "Sun & Moon Chinese Restaurant on F4; Fresh All Day Dining Restaurant on F5; Connections Lobby Lounge on F5; Pasta Factory on B1; Wochinger German Restaurant on B1",
    mealContent:
      "Sun & Moon Chinese Restaurant on F4 offers business set meals and à la carte dining. Fresh All Day Dinning Restaurant on F5 offers breakfast and dinner buffets, as well as à la carte options. Connections Lobby Lounge on F5 offers afternoon tea and cocktails. Pasta Factory on B1 offers Asian noodles and Italian pasta. Wochinger German Restaurant on B1 offers à la carte meals and beverages.",
    mealTime1:
      "Sun & Moon Chinese Restaurant on F4:\n11:30 - 14:00 (Lunch), 17:30 - 22:30 (Dinner)",
    mealTime2:
      "Fresh All Day Dinning Restaurant on F5:\n17:30 - 21:30 (Dinner), 6:30 - 22:00 (A la carte)",
    mealTime3: "Connections Lobby Lounge:\n9:00 - 24:00",
    mealTime4:
      "Pasta Factory on B1:\n11:30 - 14:00 (Lunch), 17:30 - 21:00 (Dinner)",
    mealTime5: "Wochinger German Restaurant on B1:\n17:30 - 23:00 (Dinner)",

    meetingTitle: "Conference Schedule",

    layoutTitle: "Venue Layout",
    openingCeremonyLabel: "Opening Ceremony：",
    breakoutRoomLabel1: "Breakout Room 1：",
    breakoutRoomLabel2: "Breakout Room 2：",
    privateMeetingRoom: "Private Meeting Room：",
    openingCeremony: "Hangzhou Hall 1, 2, 3, 4 (Main Conference Room)",
    breakoutRoom1: "Hangzhou Hall 5, 6, 7",
    breakoutRoom2: "Shanghai Hall",
    privateMeeting: "Chongqing Hall",

    guideTitle: "Transportation Guide",
    guideTitleText1: "Main venue、airport、high-speed railway station",
    guideTitleText2: "Nearby Hotels",
    guideMethodText: "General\nTransportation Options",
    HangzhouInternationalHotel: "InterContinental Hangzhou ICC",
    HangzhouXiaoshanAirport: "Hangzhou International Airport",
    HangzhouEastRailwayStation: "Hangzhou East Railway Station",
    supportingReceptionHotel: "Nearby hotels",

    guideMethodWay1:
      "It is recommended to use the built-in map app on your phone for navigation.",
    guideMethodWay2:
      "n mainland China, the commonly used navigation app is Gaode Maps. It can navigate to your destination, search for nearby restaurants, and view sightseeing routes, among other features.",
    guideMethodWay3:
      "When you arrive at Citizen Center Metro Station, please follow the directions illustrated in the pictures below to reach the InterContinental Hangzhou ICC.",

    guideWayFirstTitle1: "Recommended Option 1: Travel by Metro",
    guideWayFirstText1: "Operating hours: 05:50 - 00:27",
    guideWayFirstTitle2: "Recommended Option 2: Travel by Taxi",
    guideWayFirstText2:
      "T3 Pickup Area:\nLocated outside Gate 14 on the arrival level of Terminal 3.",
    guideWayFirstText3:
      "T4 Pickup Area:\nLocated at the corridor connecting Terminal 4 and Floor F1 of the Transportation Hub.",

    guideWaySecondTitle1: "Recommended Option 1: Travel by Metro",
    guideWaySecondText1: "Operating hours: 06:02 - 23:11",
    guideWaySecondTitle2: "Recommended Option 2: Travel by Taxi",
    guideWaySecondText2:
      "Hangzhoudong Railway Station has two taxi waiting areas (South and North).",

    cultureDeliveryStation: "Airport Drop-off Service",
    cultureMobile: "Emergency Helpline",
    culturePrice: "Cultural and Tourism\nServices Price List",
    cultureScenery: "Hangzhou's Scenic Spots\nand Specialties",

    travelTips: "Travel tips",

    cultureMobileTitle1: "Number",
    cultureMobileTitle2: "Type",
    cultureMobileTitle3: "Emergency",
    cultureMobileType1: "Police",
    cultureMobileType2: "Fire",
    cultureMobileType3: "Ambulance",
    cultureMobileType4: "Traffic\nAccident",
    cultureMobileEmergency1: "For theft, robbery,\nfights, etc.",
    cultureMobileEmergency2: "For fire, people\ntrapped, etc.",
    cultureMobileEmergency3: "For sudden illness,\nsevere injury, etc.",
    cultureMobileEmergency4: "For traffic accidents",
    cultureSceneryIntroTitle: "Introduction to Hangzhou's Scenic Areas",
    cultureSceneryIntroContent: `A veritable "World Heritage City", Hangzhou boasts three UNESCO World Heritages. The West Lake of Hangzhou is one of the most iconic landscape in the country. The Grand Canal, one of the world's oldest and longest artificial waterways, stretches through the heart of the city. The Archaeological Ruins of Liangzhu City, though, is a remarkable testament to China's 5,000-year-old civilization. Together, they showcase the unique facets of Chinese civilization, condensed into a distinctive urban spiritual symbol, and highlight the diverse characteristics and temperament of the region.`,
    cultureSceneryTitle1: "West Lake/Xihu ",
    cultureSceneryTitle2: "The Grand Canal",
    cultureSceneryTitle3: "Liangzhu Ancient City",
    cultureSceneryTitle4: "West Lake Longjing Tea",
    cultureSceneryTitle5: "Hangzhou Silk",
    cultureSceneryTitle6: "Traditional Pastries",
    cultureSceneryOneText1: `The West Lake Cultural Landscape of Hangzhou, comprising the West Lake and the hills surrounding its three sides, has inspired famous poets, scholars and artists since the 9th century. It comprises numerous temples, pagodas, pavilions, gardens and ornamental trees, as well as causeways and artificial islands. These additions have been made to improve the landscape west of the city of Hangzhou to the south of the Yangtze river. It owns much of its fame to its ten poetically named scenic places, known as the "Ten Views".`,
    cultureSceneryOneText2: `The "Ten Views of the West Lake" is based on the artistic creation of the Southern Song Dynasty imperial painters who were fascinated by the fabulous looks of the lake in different seasons of the year, different times of the day, under different weather conditions and enlivened by different wild lives. Each scene focuses on a variety of aesthetic themes. Even with little knowledge about Chinese classical culture, you can intuitively understand its beauty at the time.`,
    cultureSceneryTwoText1: `As a key contributor to Hangzhou's prosperity since ancient times, the Grand Canal has bred and nourished the area's Wuyue culture and Jiangnan culture, producing along its banks the iconic white-walled and black-roofed residence and folkways of a typical southern Chinese water town. Apart from the leisurely life of the locals, the famous handicraft museum cluster is also a must-see where you can experience the traditional intangible cultural heritage skills that have been kept alive to this date.`,
    cultureSceneryTwoText2: `To see the Canal, you can take the water bus from Wulinmen Pier to Gongchenqiao Pier, get off the boat and visit the such sights as the handicrafts museum group, Gongchen Bridge, Qiaoxi Historic Block, Xiaohezhijie Block, Xiaohe Park and others. Or you may take a canal cruise to Tangqi ancient town, to learn about traditions and cultures of an old jiangnan town.`,
    cultureSceneryThirdText: `Liangzhu, aka the "Land of Beauty", is a living museum where visitors can explore the remnants of this ancient civilization through archaeological sites, exhibitions, and interactive experiences. It stands as a symbol of China's rich cultural heritage and early urban development.`,
    specialProductTitle: "Introduction to Hangzhou's Specialties",
    cultureSceneryForthText1: `West Lake Longjing Tea, produced in Xihu District, Hangzhou City, Zhejiang Province, China, stands as one of the top ten famous teas in China. It is renowned for its crisp and mellow flavor as well as its unique aroma. Tea bushes thrive in the mountains surrounding the West Lake, where the humid climate and rich, fertile soil create a perfect environment for tea cultivation. Tea leaves are meticulously selected, with a focus on tender shoots that typically consist of one bud and one or two initial leaves. These leaves undergo a series of traditional processes, including withering, pan firing, rolling, and drying. `,
    cultureSceneryForthText2: `West Lake Longjing Tea boasts a clear and bright infusion, with an enhanced and lingering aroma that often hints at chestnut or bean. Its flavor is invigoratingly fresh and finishes with a delightful sweetness that lingers on the palate. The tea is more than just a refreshing drink, its unique quality and rich historical background make it an ideal gift for loved ones and a premium choice for business gatherings. As a traditional symbol of Chinese culture, it reflects the profundity of Chinese tea culture.`,
    cultureSceneryFifthText1: `Hangzhou Silk, known as one of China's four famous silks, boasts a long and storied history. Hangzhou has long been a pivotal starting point on the Silk Road since ancient times, and the silk-making techniques here have been passed down through generations, earning global acclaim. Renowned for its superior raw materials and masterful craftsmanship, Hangzhou Silk is primarily made from mulberry silk, which undergoes a series of intricate processes, including reeling, weaving, dyeing, and printing.`,
    cultureSceneryFifthText2: `Hangzhou Silk is characterized by its smooth texture, soft and muted colors, and a silk-like feel. It is highly breathable and comfortable to wear. Traditional Hangzhou Silk products include silk garments, bedding, and various handicrafts. In recent years, Hangzhou Silk has embraced innovation, with modern designs and refined techniques, making it a darling of the international fashion scene.`,
    cultureSceneryFifthText3: `As a cultural symbol of Hangzhou, Hangzhou Silk is not only a paragon of traditional Chinese craftsmanship but also a vessel of profound historical and cultural heritage. It is an exquisite gift for loved ones, as well as a premium choice for business exchanges, epitomizing the beauty of silk and the elegance of Chinese traditional culture.`,
    cultureScenerySixthText1: `Hangzhou stands as a cradle of traditional Chinese pastry culture, with many of its classic pastries favored by both locals and tourists. These delicacies capture the unique flavors and historical depth of the city. Tiaotou Cake is a representative traditional pastry of Hangzhou. Made primarily from glutinous rice flour and red bean paste, it is steamed to perfection. The outer skin is soft and chewy, with a delicate texture, and is coated with a layer of translucent syrup that is sweet but not cloying. This pastry not only delights the taste buds but also embodies the hardworking and meticulous spirit of Hangzhou's artisans.`,
    cultureScenerySixthText2: `Another classic is Dingsheng Cake, which traces its origins back to the Song Dynasty. Legend has it that Dingsheng Cake was named for its power to bring success and uplift spirits. Made from glutinous rice flour, red dates, and longan, the cake emerges from the steaming process with a dense yet springy texture, exuding a rich and fragrant sweetness. Often featured at festivals and banquets, Dingsheng Cake is cherished as a traditional delicacy that embodies wishes for good fortune and well-being.`,
    cultureScenerySixthText3: `Inspired by Hangzhou's renowned West Lake Longjing Tea, Longjing Tea Cake blends premium Longjing tea leaves with glutinous rice flour. The result is a fragrant, tender, and chewy cake, infused with the subtle sweetness of tea and a refreshing aroma. It is a perfect way to savor the elegance of Hangzhou's tea culture and pastry artistry.`,
    cultureScenerySixthText4: `These three pastries, whether enjoyed as daily snacks or given as gifts to loved ones, are representatives of Hangzhou's culinary tradition. Each bite reflects the city's profound cultural heritage and exquisite craftsmanship.`,

    locationQuery: "Seat Inquiry",
    seatMaps: "Seat Map",
    chooseCountry: "Please Select Country",

    currencySymbol: "$",
    orderContacts: "Meal Orderer",

    stand1: "Hangzhou East Railway station",
    stand2: "Hangzhou International Airport",
    stand3: "InterContinentalHangzhou lCC",

    hotel1: "Holiday Inn Hangzhou CBD",
    hotel2: "Conrad Hangzhou",
    hotel3: "Hangzhou Marriott Hotel Qianjiang",
    hotel4: "Mercure Hangzhou Qianjiang",
    hotel5: "InterContinentalHangzhou lCC",
    hotel6: "Courtyard by Marriott Hangzhou Qianjiang",
    hotel7: "Hangzhou zhongke jiutai Hotel",
    hotel8: "Jl Hotel Hangzhou Qianjiang",

    selectDate: "Select Date",
    selectTime: "Select Time",
    selectPlaceholder: "Please select",
    next: "Next Step",
    totalNumText: "Total Number of\nMeals Ordered: ",
    number: "Quantity",
    reserve: "Reserve",
    emptyOrderText: "No ordering information available at the moment",
    orderDate: "Order Date",
    orderTime: "Order Time",

    backHome: "Homepage",
    submissionSuccessful: "Submission Successful",
    continueOrdering: "Continue Ordering",
    orderSuccessTip: `Please proceed to the front desk of the Sun & Moon Chinese Restaurant on the 4th floor of the InterContinental Hangzhou ICC, show your reservation page, and pay for the meal. For reservation details, please check "Account"`,
    orderNotAuthTip: `To use this feature, please register and log in first, then select your reservation date, time, and desired business set meals. Once your submission is successful, you can view your reservation details under "Account" At mealtime, proceed to the front desk of the Sun & Moon Chinese Restaurant on the 4th floor of the InterContinental Hangzhou ICC, show your reservation page, and pay for your meal.`,
    orderAuthTip: `Please select your reservation date, time, and desired business set meals. Once your submission is successful, you can view your reservation details under "Account" At mealtime, proceed to the front desk of the Sun & Moon Chinese Restaurant on the 4th floor of the InterContinental Hangzhou ICC, show your reservation page, and pay for your meal.`,
    emptyOrderProductText: "No meal bookings available\nfor this time period",
    mealProducts: "meal products",
    orderNotStart: "Meal Ordering Service\nNot Started",
    orderEnd: "Meal Ordering Service Ended",

    deliveryNotStart: "Airport Drop-off Service\nNot Started",
    deliveryEnd: "Airport Drop-off Service Ended",
    submitted: "Submitted",
    checkDetails: "Check the Details",
    deliveryAuthTip: `Please register and log in first, then select the desired car model, pick-up time and location, flight/train number, and input the guest's name and contact information. After successful submission, vehicle arrangements will be made. Final vehicle and driver details will be notified to you via email, and you can also log in to this website under "Account" to check the details.`,
    deliveryNotAuthTip: `To use this feature, please register and log in first, then select the desired car model, pick-up time and location, flight/train number, and input the guest's name and contact information. After successful submission, vehicle arrangements will be made. Final vehicle and driver details will be notified to you via email, and you can also log in to this website under "Account" to check the details.`,
    deliverySuccessText: `Final vehicle and driver details will be notified to\nyou via email, and you can also log in to this\nwebsite under "Account" to check the details.`,
    precautions:
      "After your reservation is confirmed, please proceed to the information desk at the InterContinental Hangzhou ICC to complete the payment. Accepted payment methods: Cash, Alipay, Credit Card.\nIf you have any questions, please consult on-site staff. Contact Person: Xu Yun, +8613071821708.",
    precautionsTip: "Please check the precautions",

    selectCarModel: "Select Vehicle Type",
    pickUpLocation: "Pick-up Location",
    deliveryLocation: "Drop-off Location",
    flightOrTrainNumber: "Flight/Train Number",
    pickUpDate: "Pick-up Date",
    pickUpTime: "Pick-up Time",

    selectCarModelPlaceholder: "Please select car model",
    pickUpLocationPlaceholder: "Please select pick-up location",
    deliveryLocationPlaceholder: "Please select drop-off location",
    flightOrTrainNumberPlaceholder: "Please enter flight/train number",
    pickUpDatePlaceholder: "Please select pick-up date",
    pickUpTimePlaceholder: "Please select pick-up time",
    emailPlaceholder: "Please enter email",
    contactInformationPlaceholder: "Please enter contact information",
    myOrder: "My Order",
    myService: "My Airport\nDrop-off Service",
    submissionTime: "Submission Date",
    serving: "Serving",
    phoneNumber: "Phone Number",
    carModel: "Car Model",
    pickTime: "Pick-up time",
    noCarAssigned: "No Car\nAssigned",
    carAssigned: "Car\nAssigned",
    vehicleInformation: "Vehicle Information",
    submitDetails: "Submit Details",
    pickLocation: "Pick-up Location",
    flightTrainNumber: "Flight/Train Number",
    noInformationAvailable: "No Information Available",
    driverInfo: "Driver's Name",
    companion: "Companion",

    technologyBy: "Technology By",
    confirmPasswordPlaceholder: "Please re-enter the password for confirmation",
    passwordRegExpPlaceholder:
      "Please enter 6-20 characters, including at least any two of numbers, letters, and special characters",
    countryPlaceholder: "Please Select Country",
    searchPlaceholder: "Please enter search keywords",
    passwordNotMatch: "Passwords do not match",
    registerSuccessTip: "Successfully Registered",
    left: "LEFT",
    middle: "MIDDLE",
    right: "RIGHT",
    row: "Row",
    column: "Number",
    numEmptyText: "No seat information available at the moment",
    productPlaceholder: "Please select the dishes",
    seatImgTip: "Click image for landscape view",
    seatTip:
      "Move with one finger\nZoom with two fingers\nDouble-click to restore",
    logout: "Logout",
    know: "I got it",
    stage: "PODIUM",
  },
};

export default testMap;
