import http from "../request";
import { EntityListData } from "../type";
import { H5_PREFIX } from "@/config";

export interface ActivityMealSet {
  id: number;
  activity_id: string; // 活动id
  chinese_name: string; // 订餐时间中文名字
  english_name: string; // 订餐时间英文名字
  create_time: string;
  update_time: string;
}

const baseUrl = `${H5_PREFIX}/activity-meal-set`;

export interface ActivityMealSetApi {
  getTotalActivityMealSet: (query?: string) => Promise<ActivityMealSet[]>;
}

const activityMealSetApi: ActivityMealSetApi = {
  getTotalActivityMealSet(query) {
    const url = `${baseUrl}?pageSize=1&pageSize=9999${query || ""}`;
    return http
      .get<EntityListData<ActivityMealSet>>(url)
      .then((res) => res.data.data);
  },
};

export default activityMealSetApi;
