import http from "../request";
import { CustomResponse, EntityListData } from "@/api";
import { H5_PREFIX } from "@/config";

export interface MealSet {
  id: number;
  activity_id: number; // 活动id
  meal_date: string; // 订餐日期
  meal_time: string; // 订餐时间
  start_time: string; // 服务开始时间
  end_time: string; // 服务结束时间
  create_time: string;
  update_time: string;
}

const baseUrl = `${H5_PREFIX}/meal-set`;

export interface MealSetApi {
  getMealSetList: () => Promise<MealSet | null>;
  getMealSetStatus: () => Promise<CustomResponse>;
}

const mealSetApi: MealSetApi = {
  getMealSetList() {
    return http
      .get<EntityListData<MealSet>>(`${baseUrl}?page=1&pageSize=1`)
      .then((res) => {
        const list = res.data.data;
        if (list.length > 0) {
          return list[0];
        } else {
          return null;
        }
      });
  },
  getMealSetStatus() {
    return http.get(`${baseUrl}/list`).then((res) => res.data);
  },
};

export default mealSetApi;
