import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-piece-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_label = _resolveComponent("c-label")!
  const _component_c_button = _resolveComponent("c-button")!
  const _component_c_form = _resolveComponent("c-form")!

  return (_ctx.config)
    ? (_openBlock(), _createBlock(_component_c_form, {
        key: 0,
        class: _normalizeClass([`form-${_ctx.size}`]),
        ref: "form",
        model: _ctx.form,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "c-form-item",
              key: item.key
            }, [
              (item.component == 'title')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, " [ " + _toDisplayString(item.label) + " ] ", 1))
                : (_openBlock(), _createBlock(_component_c_label, {
                    key: 1,
                    label: item.label,
                    prop: item.key,
                    gap: item.gap,
                    must: item.must,
                    position: item.position,
                    tip: item.tip
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(`c-${item.component}`), _mergeProps(_ctx.getItemProps(item), {
                        modelValue: _ctx.form[item.key],
                        "onUpdate:modelValue": [($event: any) => ((_ctx.form[item.key]) = $event), ($event: any) => (_ctx.handleChange($event, item))]
                      }), null, 16, ["modelValue", "onUpdate:modelValue"]))
                    ]),
                    _: 2
                  }, 1032, ["label", "prop", "gap", "must", "position", "tip"]))
            ]))
          }), 128)),
          _renderSlot(_ctx.$slots, "default"),
          (_ctx.buttonText)
            ? (_openBlock(), _createBlock(_component_c_button, {
                key: 0,
                class: "form-button",
                size: _ctx.size === 'mini' ? 'middle' : 'large',
                onClick: _ctx.submit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                ]),
                _: 1
              }, 8, ["size", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class", "model", "rules"]))
    : _createCommentVNode("", true)
}