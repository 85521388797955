import { defineComponent } from "vue";
import { CarProps } from "@/ui/car/props";
import { Car } from "@/ui/car/type";
import mixin from "@/ui/form/mixin";
import "./style/car.less";
import NumberStepper from "@/components/number-stepper";

export default defineComponent({
  name: "CCar",
  props: { ...CarProps },
  mixins: [mixin],
  computed: {
    list: {
      get() {
        return this.options?.map((item) => {
          return {
            ...item,
            number: this.modelValue?.find((i) => i.id === item.id)?.number || 0,
          };
        });
      },
      set(val: Car[]) {
        const res = val?.filter((item) => item.number);
        this.$emit("update:modelValue", res?.length ? res : null);
      },
    },
    lang() {
      return this.$store.getters.lang;
    },
    isEn() {
      return this.lang === "en";
    },
    unit() {
      return this.isEn ? "$" : "￥";
    },
  },
  methods: {
    countChange(val: number, item: Car) {
      item.number = val;
      this.list = [...(this.list || [])];
    },
  },
  render() {
    return (
      <div class="c-car">
        {this.list?.map((item) => {
          return (
            <div class="car-item">
              <div class="car-item-name">
                {this.isEn ? item.english_name : item.chinese_name}
              </div>
              <div class="car-item-content">
                <div class="car-item-price-zh">
                  {this.unit}
                  {this.isEn ? item.dollar : item.price}
                </div>
                <NumberStepper
                  modelValue={item.number}
                  onUpdate:modelValue={($event: number) =>
                    this.countChange($event, item)
                  }
                  class="car-item-number"
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  },
});
