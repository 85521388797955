import http, { H5_IPCC_ACTIVITY_ID } from "../request";
import { H5_PREFIX } from "@/config";
import { ActivityDeliverySet, ActivityMealSet, CustomResponse } from "..";

export interface ActivityEntity {
  id: number;
  temple_id: number;
  name: string;
  status: number; // 1未开始 2进行中 3已结束 4创建中
  create_user_id: number;
  charge_user_name: string;
  charge_user_tel: string;
  config: string; // 模板配置
  address: string;
  file: string; // 文件地址
  people_count: number;
  is_forum: number; // 0不是 1是
  start_time: string;
  end_time: string;
  info: string;
  activity_snap: string; // 活动快照
  main_host: string; // 主办单位
  second_host: string; // 协办单位
  speech_guest: string; // 演讲嘉宾
  cover: string;
  name_en: string;
  address_en: string;
  info_en: string;
  meal_date: string; // 订餐日期
  meal_start_time: string;
  meal_end_time: string;
  delivery_start_time: string;
  delivery_end_time: string;
  create_time: string;
  update_time: string;
}

export interface ActivitySet extends ActivityEntity {
  set: Array<ActivityDeliverySet | ActivityMealSet>;
}

// const baseUrl = `${H5_PREFIX}/activity`;

export interface ActivityApi {
  getActivityOrderSet: () => Promise<CustomResponse>;
  getActivityDeliverySet: () => Promise<CustomResponse>;
}

const activitySetApi: ActivityApi = {
  getActivityOrderSet() {
    return http
      .get(
        `${H5_PREFIX}/activity-meal-set/list?activity_id=${H5_IPCC_ACTIVITY_ID}`
      )
      .then((res) => res.data);
  },
  getActivityDeliverySet() {
    return http
      .get(
        `${H5_PREFIX}/activity-delivery-set/list?activity_id=${H5_IPCC_ACTIVITY_ID}`
      )
      .then((res) => res.data);
  },
};

export default activitySetApi;
