import http from "../request";
import { EntityListData } from "../type";
import { H5_PREFIX } from "@/config";

export interface ActivityDeliverySet {
  id: number;
  activity_id: string; // 活动id
  chinese_name: string; // 车型中文名字
  english_name: string; // 车型英文名字
  price: number; // 价格
  dollar: number; // 美元
  create_time: string;
  update_time: string;
}

const baseUrl = `${H5_PREFIX}/activity-delivery-set`;

export interface ActivityDeliverySetApi {
  getTotalActivityDeliverySet: (
    query?: string
  ) => Promise<ActivityDeliverySet[]>;
}

const activityDeliverySetApi: ActivityDeliverySetApi = {
  getTotalActivityDeliverySet(query) {
    const url = `${baseUrl}?pageSize=1&pageSize=9999${query || ""}`;
    return http
      .get<EntityListData<ActivityDeliverySet>>(url)
      .then((res) => res.data.data);
  },
};

export default activityDeliverySetApi;
