import { createApp } from "vue";
import { register } from "swiper/element/bundle";
import App from "./App.vue";
import "@/common/init";
import router from "@/router";
import store from "@/store";
import ui from "@/ui";
import api from "@/api";
import request from "@/api/request";
import component from "@/components";
import "viewerjs/dist/viewer.css";
import viewer from "v-viewer";
register();
// import VConsole from "vconsole";
// const vConsole = new VConsole();

createApp(App)
  .use(store)
  .use(router)
  .use(ui)
  .use(viewer)
  .use(request)
  .use(api)
  .use(component)
  .mount("#app");
