import { ActivityMealSet, MealProductShow } from "@/api";
import { IpccState } from "@/store/type";

interface UpdateOrderValue {
  meal: MealProductShow;
  mealDate: string;
  mealTime: number;
}

export default {
  state: {
    order: null,
    timeList: null,
  },
  getters: {
    order: (state: IpccState) => state.order,
    timeList: (state: IpccState) => state.timeList,
  },
  mutations: {
    setOrder(state: IpccState, value: IpccState["order"]) {
      state.order = value;
    },
    updateOrder(state: IpccState, value: UpdateOrderValue) {
      const order = state.order;
      const { meal, mealDate = undefined, mealTime = undefined } = value;
      if (order) {
        const index = order.findIndex(
          (item: MealProductShow) =>
            item.id === meal.id &&
            mealDate === item?.mealDate &&
            mealTime === item?.mealTime
        );
        if (index === -1) {
          meal.count && order.push({ ...meal, mealDate, mealTime });
        } else {
          if (meal.count) {
            // 替换更新
            order.splice(index, 1, { ...meal, mealDate, mealTime });
          } else {
            // 删除
            order.splice(index, 1);
          }
        }
      } else {
        meal.count && (state.order = [{ ...meal, mealDate, mealTime }]);
      }
    },
    clearOrder(state: IpccState) {
      state.order = null;
    },
    setTimeList(state: IpccState, value: ActivityMealSet[]) {
      state.timeList = value;
    },
  },
};
