import { defineComponent } from "vue";
import LabelProps from "./props";
import { LabelRule, FormInstance } from "../form/type";
import { checkValue } from "../form/util";
import { LabelData } from "@/ui/label/type";
import "./style/label.less";

export default defineComponent({
  name: "CLabel",
  props: { ...LabelProps },
  data(): LabelData {
    return {
      state: "init",
    };
  },
  computed: {
    CForm() {
      let parent = this.$parent;
      while (parent && parent.$options.name !== "CForm") {
        parent = parent.$parent;
      }
      return parent as FormInstance;
    },
    labelClass(): string {
      const base = "c-label-item";
      const position = this.position;
      return `${base} c-label-${position}${
        this.state === "error" ? " c-label-error" : ""
      }${this.gap ? " c-label-gap" : ""}`;
    },
    rules(): Array<LabelRule> {
      let res: Array<LabelRule> = [];
      const CForm = this.CForm;
      if (this.prop && CForm && CForm.rules) {
        res = CForm.rules[this.prop] || [];
      }
      return res;
    },
    required(): boolean {
      if (this.must) {
        return true;
      } else {
        const rules: Array<LabelRule> = this.rules;
        return rules && rules.length > 0
          ? rules.findIndex((r) => r.required) >= 0
          : false;
      }
    },
  },
  created(): void {
    this.CForm?.addLabel(this);
  },
  beforeUnmount(): void {
    this.CForm?.removeLabel(this);
  },
  methods: {
    getValue(): unknown {
      let res = null;
      if (this.prop && this.CForm?.model) {
        res = this.CForm.model[this.prop];
      }
      return res;
    },
    checkProp(): string {
      const rules = this.rules;
      let tip = "";
      if (rules.length > 0) {
        const value = this.getValue();
        tip = checkValue(rules, value);
      }
      if (tip) {
        this.state = "error";
      } else {
        this.state = "pass";
      }
      return tip;
    },
  },
  render() {
    return (
      <div class={this.labelClass}>
        <div class="label-ti" v-show={this.label}>
          <span class="label-ti-text">{this.label}</span>
          {this.required ? <span class="c-label-must">*</span> : null}
        </div>
        <div class="c-label-content">
          <div>{this.$slots.default?.()}</div>
          {this.tip ? <div class="label-tip">{this.tip}</div> : null}
        </div>
      </div>
    );
  },
});
