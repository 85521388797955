import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { hideUI } from "@/ui";
import store from "@/store";
const base = "/";

// 路由历史记录
const paths = {
  back: history.state?.back,
  forward: history.state?.forward,
};

// 缓存的路由url
const cachePaths = [{ path: "/home", component: "HomeView" }];

store.commit(
  "setInclude",
  cachePaths.map((item) => item.component)
);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "page",
    redirect: { name: "home", replace: true },
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/info",
    name: "info",
    component: () => import(/* webpackChunkName: "info" */ "../views/Info.vue"),
  },
  {
    path: "/seat",
    name: "seat",
    component: () =>
      import(/* webpackChunkName: "info" */ "../views/seat/SeatView.vue"),
  },
  {
    path: "/lz-forum",
    name: "lzForum",
    component: () =>
      import(/* webpackChunkName: "forum" */ "../views/lz/ForumView.vue"),
    children: [
      {
        path: "",
        name: "forumHome",
        component: () =>
          import(
            /* webpackChunkName: "forumHome" */ "../views/lz/pages/ForumHome.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "hotel",
        name: "forumHotel",
        component: () =>
          import(
            /* webpackChunkName: "forumHotel" */ "../views/lz/pages/ForumHotel.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "seat",
        name: "forumSeat",
        component: () =>
          import(
            /* webpackChunkName: "forumSeat" */ "../views/lz/pages/ForumSeat.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "activity",
        name: "forumActivity",
        component: () =>
          import(
            /* webpackChunkName: "forumActivity" */ "../views/lz/pages/ForumActivity.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "schedule",
        name: "forumSchedule",
        component: () =>
          import(
            /* webpackChunkName: "forumSchedule" */ "../views/lz/pages/ForumSchedule.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "traffic",
        name: "forumTraffic",
        component: () =>
          import(
            /* webpackChunkName: "forumTraffic" */ "../views/lz/pages/ForumTraffic.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "survey",
        name: "forumSurvey",
        component: () =>
          import(
            /* webpackChunkName: "forumSurvey" */ "../views/lz/pages/ForumSurvey.vue"
          ),
        meta: {
          title: "",
        },
      },
    ],
  },
  {
    path: "/hz-riefing",
    name: "hzRiefing",
    component: () =>
      import(
        /* webpackChunkName: "riefing" */ "../views/riefing/RiefingView.vue"
      ),
    children: [
      {
        path: "",
        name: "riefingHome",
        component: () =>
          import(
            /* webpackChunkName: "riefingHome" */ "../views/riefing/pages/RiefingHome.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "sign",
        name: "riefingSign",
        component: () =>
          import(
            /* webpackChunkName: "riefingSign" */ "../views/riefing/pages/SignIn.vue"
          ),
      },
      {
        path: "register",
        name: "riefingRegister",
        component: () =>
          import(
            /* webpackChunkName: "riefingRegister" */ "../views/riefing/pages/RiefingRegister.vue"
          ),
      },
      {
        path: "schedule",
        name: "riefingSchedule",
        component: () =>
          import(
            /* webpackChunkName: "riefingSchedule" */ "../views/riefing/pages/RiefingSchedule.vue"
          ),
      },
    ],
  },
  {
    path: "/ipcc",
    name: "ipcc",
    component: () =>
      import(/* webpackChunkName: "ipcc" */ "../views/ipcc/IpccView.vue"),
    children: [
      {
        path: "",
        name: "ipccHome",
        component: () =>
          import(
            /* webpackChunkName: "ipccHome" */ "../views/ipcc/pages/IpccHome.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "mine",
        name: "ipccMine",
        component: () =>
          import(
            /* webpackChunkName: "ipccMine" */ "../views/ipcc/pages/IpccMine.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "login",
        name: "ipccLogin",
        component: () =>
          import(
            /* webpackChunkName: "ipccLogin" */ "../views/ipcc/pages/IpccLogin.vue"
          ),
      },
      {
        path: "register",
        name: "ipccRegister",
        component: () =>
          import(
            /* webpackChunkName: "ipccRegister" */ "../views/ipcc/pages/IpccRegister.vue"
          ),
      },
      {
        path: "reception",
        name: "ipccReception",
        component: () =>
          import(
            /* webpackChunkName: "ipccReception" */ "../views/ipcc/pages/IpccReception.vue"
          ),
      },
      {
        path: "service",
        name: "ipccService",
        component: () =>
          import(
            /* webpackChunkName: "ipccService" */ "../views/ipcc/pages/IpccService.vue"
          ),
      },
      {
        path: "service-material",
        name: "ipccServiceMaterial",
        component: () =>
          import(
            /* webpackChunkName: "ipccServiceMaterial" */ "../views/ipcc/pages/IpccServiceMaterial.vue"
          ),
      },
      {
        path: "service-info",
        name: "ipccServiceInfo",
        component: () =>
          import(
            /* webpackChunkName: "ipccServiceInfo" */ "../views/ipcc/pages/IpccServiceInfo.vue"
          ),
      },
      {
        path: "service-network",
        name: "ipccServiceNetwork",
        component: () =>
          import(
            /* webpackChunkName: "ipccServiceNetwork" */ "../views/ipcc/pages/IpccServiceNetwork.vue"
          ),
      },
      {
        path: "service-health",
        name: "ipccServiceHealth",
        component: () =>
          import(
            /* webpackChunkName: "ipccServiceHealth" */ "../views/ipcc/pages/IpccServiceHealth.vue"
          ),
      },
      {
        path: "service-tea",
        name: "ipccServiceTea",
        component: () =>
          import(
            /* webpackChunkName: "ipccServiceTea" */ "../views/ipcc/pages/IpccServiceTea.vue"
          ),
      },
      {
        path: "service-meal",
        name: "ipccServiceMeal",
        component: () =>
          import(
            /* webpackChunkName: "ipccServiceMeal" */ "../views/ipcc/pages/IpccServiceMeal.vue"
          ),
      },
      {
        path: "layout",
        name: "ipccLayout",
        component: () =>
          import(
            /* webpackChunkName: "ipccLayout" */ "../views/ipcc/pages/IpccLayout.vue"
          ),
      },
      {
        path: "guide",
        name: "ipccGuide",
        component: () =>
          import(
            /* webpackChunkName: "ipccGuide" */ "../views/ipcc/pages/IpccGuide.vue"
          ),
      },
      {
        path: "guide-method",
        name: "ipccGuideMethod",
        component: () =>
          import(
            /* webpackChunkName: "ipccGuideMethod" */ "../views/ipcc/pages/IpccGuideMethod.vue"
          ),
      },
      {
        path: "guide-way-first",
        name: "ipccGuideWayFirst",
        component: () =>
          import(
            /* webpackChunkName: "ipccGuideWayFirst" */ "../views/ipcc/pages/IpccGuideWayFirst.vue"
          ),
      },
      {
        path: "guide-way-second",
        name: "ipccGuideWaySecond",
        component: () =>
          import(
            /* webpackChunkName: "ipccGuideWaySecond" */ "../views/ipcc/pages/IpccGuideWaySecond.vue"
          ),
      },
      {
        path: "guide-way-third",
        name: "ipccGuideWayThird",
        component: () =>
          import(
            /* webpackChunkName: "ipccGuideWayThird" */ "../views/ipcc/pages/IpccGuideWayThird.vue"
          ),
      },
      {
        path: "food",
        name: "ipccFood",
        component: () =>
          import(
            /* webpackChunkName: "ipccFood" */ "../views/ipcc/pages/IpccFood.vue"
          ),
      },
      {
        path: "food-order",
        name: "ipccFoodOrder",
        component: () =>
          import(
            /* webpackChunkName: "ipccFoodOrder" */ "../views/ipcc/pages/IpccFoodOrder.vue"
          ),
      },
      {
        path: "product/:id",
        name: "ipccProduct",
        component: () =>
          import(
            /* webpackChunkName: "ipccProduct" */ "../views/ipcc/pages/IpccProduct.vue"
          ),
      },
      {
        path: "introduce",
        name: "ipccIntroduce",
        component: () =>
          import(
            /* webpackChunkName: "ipccIntroduce" */ "../views/ipcc/pages/IpccIntroduce.vue"
          ),
      },
      {
        path: "culture",
        name: "ipccCulture",
        component: () =>
          import(
            /* webpackChunkName: "ipccCulture" */ "../views/ipcc/pages/IpccCulture.vue"
          ),
      },
      {
        path: "culture-delivery-station",
        name: "ipccCultureDeliveryStation",
        component: () =>
          import(
            /* webpackChunkName: "ipccCultureDeliveryStation" */ "../views/ipcc/pages/IpccCultureDeliveryStation.vue"
          ),
      },
      {
        path: "culture-mobile",
        name: "ipccCultureMobile",
        component: () =>
          import(
            /* webpackChunkName: "ipccCultureMobile" */ "../views/ipcc/pages/IpccCultureMobile.vue"
          ),
      },
      {
        path: "culture-scenery",
        name: "ipccCultureScenery",
        component: () =>
          import(
            /* webpackChunkName: "ipccCultureScenery" */ "../views/ipcc/pages/IpccCultureScenery.vue"
          ),
      },
      {
        path: "culture-price",
        name: "ipccCulturePrice",
        component: () =>
          import(
            /* webpackChunkName: "ipccCulturePrice" */ "../views/ipcc/pages/IpccCulturePrice.vue"
          ),
      },
      {
        path: "seat",
        name: "ipccSeat",
        component: () =>
          import(
            /* webpackChunkName: "ipccSeat" */ "../views/ipcc/pages/IpccSeat.vue"
          ),
      },
      {
        path: "seat-search",
        name: "ipccSeatSearch",
        component: () =>
          import(
            /* webpackChunkName: "ipccSeatSearch" */ "../views/ipcc/pages/IpccSeatSearch.vue"
          ),
      },
      {
        path: "seat-search-result",
        name: "ipccSeatSearchResult",
        component: () =>
          import(
            /* webpackChunkName: "ipccSeatSearchResult" */ "../views/ipcc/pages/IpccSeatSearchResult.vue"
          ),
      },
      {
        path: "seat-layout",
        name: "ipccSeatLayout",
        component: () =>
          import(
            /* webpackChunkName: "ipccSeatLayout" */ "../views/ipcc/pages/IpccSeatLayout.vue"
          ),
      },
      {
        path: "car-detail/:id",
        name: "ipccCar",
        component: () =>
          import(
            /* webpackChunkName: "ipccCar" */ "../views/ipcc/pages/IpccCar.vue"
          ),
      },
      {
        path: "seat-meeting",
        name: "ipccMeeting",
        component: () =>
          import(
            /* webpackChunkName: "ipccMeeting" */ "../views/ipcc/pages/IpccMeeting.vue"
          ),
      },
    ],
  },
  {
    path: "/reception/:id",
    name: "reception",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/reception/Reception.vue"),
    children: [
      {
        path: "",
        name: "receptionHome",
        component: () =>
          import(
            /* webpackChunkName: "receptionHome" */ "../views/reception/Home.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "al",
        name: "ArticleList",
        component: () =>
          import(
            /* webpackChunkName: "al" */ "../views/reception/ArticleList.vue"
          ),
        meta: {
          title: "活动列表",
        },
      },
      {
        path: "ad/:aid",
        name: "ArticleDetail",
        component: () =>
          import(
            /* webpackChunkName: "ad" */ "../views/reception/ArticleDetail.vue"
          ),
        meta: {
          title: "",
        },
      },
      {
        path: "fl",
        name: "FileList",
        component: () =>
          import(
            /* webpackChunkName: "al" */ "../views/reception/FileList.vue"
          ),
        meta: {
          title: "文件列表",
        },
      },
      {
        path: "ll",
        name: "LiveList",
        component: () =>
          import(
            /* webpackChunkName: "ll" */ "../views/reception/LiveList.vue"
          ),
        meta: {
          title: "直播视频列表",
        },
      },
      {
        path: "ld/:lid",
        name: "LiveDetail",
        component: () =>
          import(
            /* webpackChunkName: "ld" */ "../views/reception/LiveDetail.vue"
          ),
        meta: {
          title: "视频列表",
        },
      },
      {
        path: "schedule",
        name: "Schedule",
        component: () =>
          import(
            /* webpackChunkName: "schedule" */ "../views/reception/Schedule.vue"
          ),
        meta: {
          title: "活动日程",
        },
      },
      {
        path: "invite",
        name: "invite",
        component: () =>
          import(/* webpackChunkName: "invite" */ "../views/invite/Invite.vue"),
        meta: {
          title: "邀请函",
        },
      },
      {
        path: "sign",
        name: "sign",
        component: () =>
          import(/* webpackChunkName: "sign" */ "../views/sign/Sign.vue"),
        meta: {
          title: "签到",
        },
      },
      {
        path: "dinner",
        name: "dinner",
        component: () =>
          import(
            /* webpackChunkName: "dinner" */ "../views/reception/Dinner.vue"
          ),
        meta: {
          title: "用餐安排",
        },
      },
      {
        path: "stay",
        name: "stay",
        component: () =>
          import(/* webpackChunkName: "stay" */ "../views/reception/Stay.vue"),
        meta: {
          title: "住宿安排",
        },
      },
      {
        path: "journey",
        name: "journey",
        component: () =>
          import(
            /* webpackChunkName: "journey" */ "../views/reception/Journey.vue"
          ),
        meta: {
          title: "行程安排",
        },
      },

      {
        path: "enroll",
        name: "enroll",
        component: () =>
          import(
            /* webpackChunkName: "enroll" */ "../views/reception/Enroll.vue"
          ),
        meta: {
          title: "报名",
        },
      },
      {
        path: "guide",
        name: "guide",
        component: () =>
          import(
            /* webpackChunkName: "guide" */ "../views/reception/Guide.vue"
          ),
        meta: {
          title: "交通导览",
        },
      },
      {
        path: "voucher",
        name: "voucher",
        component: () =>
          import(
            /* webpackChunkName: "voucher" */ "../views/reception/Voucher.vue"
          ),
        meta: {
          title: "电子凭证",
        },
      },
      {
        path: "forum",
        name: "forum",
        component: () =>
          import(
            /* webpackChunkName: "forum" */ "../views/reception/ForumList.vue"
          ),
        meta: {
          title: "会议论坛",
        },
      },
      {
        path: "forum-detail",
        name: "forumDetail",
        component: () =>
          import(
            /* webpackChunkName: "forumDetail" */ "../views/reception/ForumDetail.vue"
          ),
        meta: {
          title: "论坛",
        },
      },
    ],
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
    meta: {
      title: "正在登陆...",
    },
  },
];

const router = createRouter({
  history: createWebHistory(base),
  routes,
});

router.beforeEach((to, from, next) => {
  hideUI();
  // 返回上一个路由采用返回的过渡动画
  const back = paths.back === to.fullPath;
  if (back) {
    from.meta.transition = from.meta.transition || "slide-left";
    to.meta.transition = to.meta.transition || "slide-right";
  } else {
    // 不是初始页面
    if (from.name) {
      from.meta.transition = from.meta.transition || "slide-right";
      to.meta.transition = to.meta.transition || "slide-left";
    } else {
      to.meta.transition = "immediately";
    }
  }
  // 在缓存的路由页面后退则去掉缓存
  const index = cachePaths.findIndex((p) => p.path === from.fullPath);
  if (back && index >= 0) {
    store.commit("setExclude", [cachePaths[index].component]);
  } else {
    store.commit("setExclude", []);
  }
  const meta = to.meta;
  if (typeof meta === "object" && meta.title) {
    document.title = meta.title + "";
  }
  next();
});
router.afterEach(() => {
  paths.back = history.state.back;
  paths.forward = history.state.forward;
});
export default router;
