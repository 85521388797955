import http from "../request";
import { H5_PREFIX } from "@/config";
import { CustomResponse, EntityListData } from "..";

export interface DeliverySet {
  id: number;
  activity_id: string;
  car_info: string; // 车类型+价格(json)
  start_time: string; // 开始时间
  end_time: string; // 结束时间
  create_time: string;
  update_time: string;
}

const baseUrl = `${H5_PREFIX}/delivery-set`;

export interface DeliverySetApi {
  getDeliveryServiceList: () => Promise<DeliverySet | null>;
  getDeliverySetStatus: () => Promise<CustomResponse>;
}

const deliverySetApi: DeliverySetApi = {
  getDeliveryServiceList() {
    return http
      .get<EntityListData<DeliverySet>>(`${baseUrl}?page=1&pageSize=1`)
      .then((res) => {
        const list = res.data.data;
        if (list.length > 0) {
          return list[0];
        } else {
          return null;
        }
      });
  },
  getDeliverySetStatus() {
    return http.get(`${baseUrl}/list`).then((res) => res.data);
  },
};

export default deliverySetApi;
