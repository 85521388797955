import http from "../request";
import { CustomResponse, EntityListData } from "@/api";
import { H5_PREFIX } from "@/config";
import { getUserId } from "@/common/storage";
import { MealOrderDetail } from "@/api";

export interface MealOrder {
  id: number;
  h_user_id: number; // h5用户ID
  activity_id: number; // 活动id
  user_name: string; // 订餐联系人
  mobile: string; // 联系电话
  total_num: number; // 总的数量
  orderDetails?: MealOrderDetail[];
  create_time: string;
  update_time: string;
}

export interface PostProduct {
  product_id: number;
  num: number;
  meal_date: string;
  activity_meal_set_id: number;
}

export interface MealOrderData {
  product: PostProduct[];
  user_name: string; // 订餐联系人
  mobile: string; // 联系电话
  total_num: number; // 总的数量
}

const baseUrl = `${H5_PREFIX}/meal-order`;

export interface MealOrderApi {
  postMealOrder: (data: MealOrderData) => Promise<CustomResponse>;
  getUserMealOrder: () => Promise<MealOrder[]>;
}

const mealOrderApi: MealOrderApi = {
  postMealOrder(data) {
    return http.post(`${baseUrl}/order`, data).then((res) => res.data);
  },
  getUserMealOrder() {
    const id = getUserId(H5_PREFIX);
    return http
      .get<EntityListData<MealOrder>>(
        `${baseUrl}?expand=orderDetails.mealProduct,orderDetails.activityMealSet&filter[h_user_id]=${id}`
      )
      .then((res) => res.data.data);
  },
};

export default mealOrderApi;
