import http from "../request";
import { EntityListData } from "../type";
import { H5_PREFIX } from "@/config";

export enum HSeatRegionEnum {
  LEFT = "LEFT",
  MIDDLE = "MIDDLE",
  RIGHT = "RIGHT",
}
export type HSeatRegion = "LEFT" | "MIDDLE" | "RIGHT";
export interface HSeat {
  id: number;
  activity_id: string; // 活动id
  country: string; // 国家
  country_initial: string; // 国家首字母
  region: HSeatRegion; // 区域
  row: number; // 排
  columns: number; // 列
  create_time: string;
  update_time: string;
}

const baseUrl = `${H5_PREFIX}/h-seat`;

export interface HSeatApi {
  getTotalHSeatSet: (query?: string) => Promise<HSeat[]>;
}

const hSeatApi: HSeatApi = {
  getTotalHSeatSet(query) {
    const url = `${baseUrl}?pageSize=1&pageSize=9999${query || ""}`;
    return http.get<EntityListData<HSeat>>(url).then((res) => res.data.data);
  },
};

export default hSeatApi;
